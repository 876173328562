<template>
    <div id="section3">
        <div id="sec3-text">
            <h2>Unified Data</h2>
            <h2>Management Solution</h2>
            <h3>Effortlessly resolve the challenges of fragmented data across multiple platforms with The Managed's centralized and seamless data management solution.</h3>
        </div>
        <div id="badges">
            <div id="badge-row-1">
                <badge-comp h2="Microsoft Excel" h3="Requisitions_Equipment_v4" img="0"></badge-comp>
                <badge-comp h2="Joe Summers" h3="Hey, can you approve this..." img="1"></badge-comp>
            </div>
            <div id="badge-row-2">
                <badge-comp h2="Calendar" h3="Budget Report for Q2" img="2"></badge-comp>
                <badge-comp h2="Mithun Jaun" h3="Hello, wanted a quotation" img="3"></badge-comp>
            </div>
        </div>
        <div id="animated-image">
            <img :src="require('../../assets/product/animation-image.png')" alt="Manage">
        </div>
    </div>
</template>

<script>
import BadgeComp from '../general/badge-comp.vue'
export default {
    components:{BadgeComp}
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section3{
        width:94vw;
        overflow: hidden;
        margin-top:6.75vh;
        margin-left:3vw;
        margin-right:3vw;
    }
    #sec3-text{
        width:90vw;
        padding-left:2vw;
        padding-right:2vw;
    }
    #sec3-text h2{
        color: #FFF;
        text-align: center;
        font-family: sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 37.584px */
        letter-spacing: 0.96px;
        margin-top:0;
        margin-bottom:.5vh;
    }
    #sec3-text h3{
        color: #FFF;

        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 150% */
    }
    #badges{
        display: none;
    }
    #badge-row-1{
        display: none;
    }
    #badge-row-2{
       display: none;
    }
    #animated-image{
        margin-top:5vh;
        width:94vw;
    }
    #animated-image img{
        width:94vw;
        border-radius:18px;
        height:44vh;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section3{
        width:90vw;
        overflow: hidden;
        margin-top:4.75vh;
        margin-left:5vw;
        margin-right:5vw;
    }
    #sec3-text{
        width:90vw;
        padding-left:2vw;
        padding-right:2vw;
    }
    #sec3-text h2{
        color: #FFF;
        text-align: center;
        font-family: sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 37.584px */
        letter-spacing: 0.96px;
        margin-top:0;
        margin-bottom:.5vh;
    }
    #sec3-text h3{
        color: #FFF;

        text-align: center;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 150% */
    }
    #badges{
        display: none;
    }
    #badge-row-1{
        display: none;
    }
    #badge-row-2{
       display: none;
    }
    #animated-image{
        margin-top:5vh;
        width:94vw;
    }
    #animated-image img{
        width:94vw;
        border-radius:18px;
        height:44vh;
    }
}
@media only screen and (min-width:961px){
    #section3{
        width:82vw;
        overflow: hidden;
        margin-top:15vh;
        margin-left:9vw;
        margin-right:9vw;
    }
    #sec3-text{
        width:36vw;
        padding-left:2vw;
        padding-right:2vw;
        margin-left:21vw;
        margin-right:21vw;
    }
    #sec3-text h2{
        color: #FFF;
        text-align: center;
        font-family: sans-serif;
        font-size: 37.406px;
        font-style: normal;
        font-weight: 900;
        line-height: normal; /* 0px */
        letter-spacing: 1.896px;
        margin-top:0;
        margin-bottom:2.5vh;
    }
    #sec3-text h3{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 41.722px; /* 150% */
        margin-top:2vh;
    }
    #badges{
        margin-top:-25vh;
    }
    #badge-row-1{
        display:flex;
        width:78vw;
        padding-left:2vw;
        padding-right:2vw;
        gap:48vw;
        margin-bottom:0;
    }
    #badge-row-2{
        display:flex;
        width:70vw;
        padding-left:6vw;
        padding-right:6vw;
        gap:42vw;
        margin-top:9.6vh;
    }
    #animated-image{
        margin-top:10vh;
        width:82vw;
    }
    #animated-image img{
        width:82vw;
        border-radius:18px;
        height:110vh;
    }
}
</style>