<template>
    <div id="section1">
        <div id="sec1-text">
            <h2>Our Product</h2>
            <p>The Manage can be integrated with your existing systems and database and features can be customized as per your requirement.</p>
            <div id="sec1-btn" @click="redirect">Get in Touch</div>   
        </div>
        <div id="sec1-video">
            <video autoplay muted loop id="video-bg">
                <source :src="require('../../assets/video/cube-bg.mp4')" type="video/mp4" id="video-source"/>
            </video>
        </div>
    </div>
</template>

<script>
import {ref,computed,onMounted} from 'vue';
import {useRouter} from 'vue-router'
export default {
    setup() {
        const index = ref(0)
        const router = useRouter()
        const Index = computed(()=>{
            return index.value;
        })
        function rotate(){
            if(index.value==3){index.value=0}
            else index.value = index.value +1
            setTimeout(()=>{
                rotate()
            },3000)
        }
        onMounted(()=>{
            setTimeout(()=>{
                rotate()
            },3000)
        })
        function redirect(){
            router.push({name:'contactPage'})
        }
        return{
            Index,
            redirect
        }
    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@600&family=Quicksand&display=swap');
@media only screen and (max-width:480px){
    *{
        overflow-x:hidden
    }
    #section1{
      width:100vw;
      display: flex;
      flex-direction: row;
      justify-content:center;
      margin-top:6vh;
      padding-left:15vw;
      overflow: hidden;
    }
    #sec1-text{
        width: 85vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #sec1-text h2{
        color: #FFF;
text-align: center;
        font-family: Anek Latin;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 156.6%; /* 31.32px */
        letter-spacing: 0.8px;
margin-bottom:0;
margin-top:0vh;
    }
    #sec1-text p{
        color: #FDFDFD;

        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 178.571% */
        text-transform: capitalize;
width:65vw;
margin-top:2.5vh;
    }
    #sec1-btn{
        width:50vw;
        height:7vh;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 10.172px;
        background: linear-gradient(180deg, #2513B7 0%, #4A48F3 122.73%);
        box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
        color: #FFF;
        
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.27px; /* 132.697% */
        letter-spacing: 0.9px;
        margin-top:3vh;
        cursor: pointer;
            }
    #sec1-video{
    width:40vw;
    overflow:hidden;
    }
    #video-bg{
        width: 60vw;
        height: 80%;
        overflow:hidden;
    }
    #video-source{
        width: 60vw;
        height: 80%;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    *{
        overflow-x:hidden
    }
    #section1{
      width:100vw;
      display: flex;
      flex-direction: row;
      justify-content:center;
      margin-top:3.5vh;
      padding-left:20vw;
      overflow: hidden;
    }
    #sec1-text{
        width: 55vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #sec1-text h2{
color: #FFF;
text-align: center;
font-family: Anek Latin;
font-size: 35.618px;
font-style: normal;
font-weight: bolder;
line-height: 156.6%; /* 87.098px */
letter-spacing: 2.225px;
margin-bottom:0;
margin-top:1vh;
    }
    #sec1-text h3{
text-align: center;
font-family: Anek Latin;
font-size: 42.618px;
font-style: normal;
font-weight: bolder;
line-height: 156.6%; /* 87.098px */
letter-spacing: 2.225px;
margin-top:0px;
background-image: linear-gradient(80deg, #4749E7 44.38%, #FFF 100.59%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
margin-bottom:0
    }
    #sec1-text h4{
        color: #FFF;
text-align: center;
font-family: Anek Latin;
font-size: 35.618px;
font-style: normal;
font-weight: bolder;
line-height: 156.6%; /* 87.098px */
letter-spacing: 2.225px;
margin-bottom:0;
margin-top:2vh;
animation:animatetext 2s forwards;
    }
@keyframes animatetext{
    0% {
        transform: translateY(20%); /* Start position below the container */
      }
      100% {
        transform: translateY(0); /* Move up to the container */
      }
}
    #sec1-text p{
color: #FDFDFD;
text-align: center;
font-family: Inter;
font-size: 18.069px;
font-style: normal;
font-weight: 400;
line-height: 34.603px; /* 150% */
text-transform: capitalize;
width:50vw;
margin-left:2.5vw;
margin-top:2.5vh;
    }
    #sec1-btn{
width: 198.747px;
height: 60.25px;
display:flex;
align-items: center;
justify-content: center;
border-radius: 10.172px;
background: linear-gradient(180deg, #2513B7 0%, #4A48F3 122.73%);
box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16.432px;
font-style: normal;
font-weight: 700;
line-height: 24.648px; /* 150% */
letter-spacing: 1.479px;
margin-top:2vh;
cursor: pointer;
    }
    #sec1-video{
    width:40vw;
    height:20vh;
    overflow:hidden;
    }
    #video-bg{
        width: 40vw;
        height: 20vh;
        z-index: -1;
        overflow:hidden;
    }
    #video-source{
        width: 40vw;
        height: 60%;
    }
}
@media only screen and (min-width:961px){
    *{
        overflow-x:hidden
    }
    #section1{
      width:100vw;
      display: flex;
      flex-direction: row;
      justify-content:center;
      margin-top:0vh;
      padding-left:20vw;
      overflow: hidden;
      margin-top:-2vh;
      margin-bottom:0;
    }
    #sec1-text{
        width: 55vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:0;
    }
    #sec1-text h2{
color: #FFF;
text-align: center;
font-family: Anek Latin;
font-size: 35.618px;
font-style: normal;
font-weight: bolder;
line-height: 156.6%; /* 87.098px */
letter-spacing: 2.225px;
margin-bottom:1.5vh;
    }
    #sec1-text p{
color: #FDFDFD;
text-align: center;
font-family: Inter;
font-size: 21.069px;
font-style: normal;
font-weight: 400;
line-height: 34.603px; /* 150% */
text-transform: capitalize;
width:40vw;
margin-left:2.5vw;
margin-top:1vh;
    }
    #sec1-video{
    width:40vw;
    height:60vh;
    overflow:hidden;
    }
    #video-bg{
        width: 40vw;
        height: 60vh;
        z-index: -1;
        overflow:hidden;
    }
    #video-source{
        width: 40vw;
        height: 60vh;
    }
    #sec1-btn{
        width: 198.747px;
        height: 60.25px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 10.172px;
        background: linear-gradient(180deg, #2513B7 0%, #4A48F3 122.73%);
        box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16.432px;
        font-style: normal;
        font-weight: 700;
        line-height: 24.648px; /* 150% */
        letter-spacing: 1.479px;
        margin-top:3vh;
        cursor: pointer;
            }
}
</style>