<template>
    <div id="section7">
       <img :src="require('../../assets/general/cube-transparent.png')" alt="Managed" id="sec7-image-left">
        <div id="sec7-content">
            <h2>Get in touch</h2>
            <h3>Unlock the potential of your supply chain with The Manage. Reach out to get more product insights, demo, or a personalized quote. Your journey to enhanced supply chain excellence starts here!</h3>
            <div id="sec7-input">
                <div id="input-box">
                   <img :src="require('../../assets/landing/icons/mail.png')" alt="Email">
                   <input type="text" placeholder="Enter your E-mail" v-model="email">
                </div>
                <div id="sec7-button">Confirm</div>
            </div>
        </div>
       <img :src="require('../../assets/general/cube-transparent.png')" alt="Managed" id="sec7-image-right">
    </div>
</template>

<script>
import {ref} from 'vue';
export default {
    setup() {
        const email = ref('')
        return{
            email 
        }
    },
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #section7{
        width:100vw;
        margin-top:1vh;
    }
    #sec7-image-left{
       display: none;
    }
    #sec7-image-right{
       display:none;
      
    }
    #sec7-content{
        width:94vw;
       padding-left:3vw;
       padding-right:3vw;
    }
    #sec7-content h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 125.269px; /* 835.128% */
letter-spacing: 1.825px;
margin-bottom:0;
    }
    #sec7-content h3{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 192.857% */
        margin-top:-6vh;
    }
    #sec7-input{
        width:94vw;
        display:flex;
        flex-direction: row;
        justify-content: center;
        gap:1vw;
        margin-top:6vh;
    }
    #input-box{
        border-radius: 61px;
        background: #F8F9FB;
        width:54vw;
        height:41px;
        padding-left:1.5vw;
        padding-right:1vw;
        display:flex;
        flex-direction: row;
        align-items:center;
    }
    #input-box img{
        width:23px;
        height:23px;
        margin-right:1vw;
    }
    #input-box input,
    #input-box input:focus,
    #input-box input::selection{
        height:41px;
        width:50vw;
        border:none;
        outline:none;
        border-radius:61px;
        background: transparent;
        color: #393d3d;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    #input-box input::placeholder{
        color: #6E7474;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    #sec7-button{
        width:40vw;
        height:41px;
        border-radius: 38.5px;
        background: #2613B7;
        display:flex;
        align-items: center;
        justify-content: center;
        color: #FFF;

        text-align: center;
        font-family: Quicksand;
        font-size: 15.6px;
        font-style: normal;
        font-weight: 700;
        line-height: 13.53px; /* 150% */
margin-left:2.5vw;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section7{
        width:100vw;
        display:grid;
        grid-template-columns:10vw 80vw 10vw;
        overflow:hidden;
        margin-top:2vh;
    }
    #sec7-image-left{
        width:80vw;
        height:40vh;
        margin-top:15vh;
        transform: rotate(-168.355deg);
        opacity: 0.5;
        margin-left:-40vw;
    }
    #sec7-image-right{
        width:80vw;
        height:40vh;
        margin-top:-8vh;
        transform: rotate(11.645deg);
        margin-left:-25vw;
        opacity: 0.4;
      
    }
    #sec7-content{
        width:80vw;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        margin-top:-10vh;
    }
    #sec7-content h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 35.483px;
font-style: normal;
font-weight: 600;
line-height: 135.269px; /* 258.375% */
letter-spacing: 1.825px;
margin-bottom:0pc;
    }
    #sec7-content h3{
        color: white;
        font-family: Inter;
        font-size: 21.815px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.722px;
        margin-top:0;
        text-align: center;
line-height: 33px; /* 150% */
width:80vw;
margin-top:-1vh;
margin-bottom:5.5vh;
    }
    #sec7-input{
        width:80vw;
        display:flex;
        flex-direction: row;
        justify-content: center;
        margin-top:-1vh;
        z-index: 1
    }
    #input-box{
        border-radius: 61px;
        background: #F8F9FB;
        width:55vw;
        height:61px;
        padding-left:1.5vw;
        padding-right:1.5vw;
        display:flex;
        flex-direction: row;
        align-items:center;
    }
    #input-box img{
        width:40px;
        height:40px;
        margin-right:1.25vw;
    }
    #input-box input,
    #input-box input:focus,
    #input-box input::selection{
        height:61px;
        width:44vw;
        border:none;
        outline:none;
        border-radius:61px;
        background: transparent;
        color: #393d3d;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    #input-box input::placeholder{
        color: #6E7474;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    #sec7-button{
        width:35vw;
        height:61px;
        border-radius: 38.5px;
        background: #2613B7;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #FFF;
text-align: center;
font-family: Quicksand;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: 31.5px; /* 150% */
margin-left:2.5vw;
    }
}
@media only screen and (min-width:961px){
    #section7{
        width:100vw;
        display:grid;
        grid-template-columns:20vw 60vw 20vw;
        overflow:hidden;
        margin-top:8vh;
    }
    #sec7-image-left{
        width:50vw;
        height:50vh;
        margin-top:10vh;
        margin-left:-25vw;
        transform: rotate(-168.355deg);
        opacity: 0.5;
    }
    #sec7-image-right{
        width:50vw;
        height:50vh;
        margin-top:-10vh;
        transform: rotate(11.645deg);
        margin-left:-5vw;
        opacity: 0.4;
      
    }
    #sec7-content{
        width:60vw;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }
    #sec7-content h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 125.269px; /* 258.375% */
letter-spacing: 1.825px;
margin-bottom:-10px;
    }
    #sec7-content h3{
        color: white;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.722px;
        margin-top:0;
        text-align: center;
line-height: 30px; /* 150% */
width:60vw;
margin-top:0;
margin-bottom:5.5vh;
    }
    #sec7-input{
        width:60vw;
        display:flex;
        flex-direction: row;
        justify-content: center;
        margin-top:0;
    }
    #input-box{
        border-radius: 61px;
        background: #F8F9FB;
        width:28vw;
        height:51px;
        padding-left:1vw;
        padding-right:1vw;
        display:flex;
        flex-direction: row;
        align-items:center;
    }
    #input-box img{
        width:36px;
        height:36px;
        margin-right:1vw;
    }
    #input-box input,
    #input-box input:focus,
    #input-box input::selection{
        height:51px;
        width:24vw;
        border:none;
        outline:none;
        border-radius:61px;
        background: transparent;
        color: #393d3d;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    #input-box input::placeholder{
        color: #6E7474;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    #sec7-button{
        width:12vw;
        height:51px;
        border-radius: 38.5px;
        background: #2613B7;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #FFF;
text-align: center;
font-family: Quicksand;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: 31.5px; /* 150% */
margin-left:2.5vw;
    }
}
</style>