<template>
    <div id="navigation">
        <img :src="require('../../assets/general/icon.png')" alt="Managed" id="nav-logo" @click="setPage(1,'landingPage')">
        <div id="navigations">
            <div :class="Active=='1'?'navigation-active':'navigation-passive'" @click="setPage(1,'landingPage')">Home</div>
            <div :class="Active=='2'?'navigation-active':'navigation-passive'" @click="setPage(2,'aboutPage')">About Us</div>
            <div :class="Active=='3'?'navigation-active':'navigation-passive'" @click="setPage(3,'productPage')">Products</div>
        </div>
        <div id="contact" @click="setPage(4,'contactPage')">Connect</div>
    </div>
</template>

<script>
import {computed} from 'vue';
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
export default {
    setup() {
        const router = useRouter()
        const store = useStore()
        const Active = computed(()=>{
        return store.getters['getActivePage']
    })
    const setPage = (index,page)=>{
            store.commit('setActivePage',index)
            router.push({name:page})
    }
        return{
         Active,
         setPage
        }
    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #navigation{
        display: none;
    }
}
@media only screen and (min-width:480px) and (max-width:960px){
    #navigation{
        width:100vw;
        margin-top:2vh;
       
        display: flex;
        flex-direction: row;
        gap:12vw;
        justify-content: center;
        align-items:center;
        overflow-x:hidden;
   }
   #nav-logo{
       width: 60.002px;
       height: 45.066px;
       flex-shrink: 0;
   }
   #navigations{
       display: flex;
       flex-direction: row;
       justify-content: center;
       gap:4vw;
   }
   .navigation-active{
       width: 110px;
       height: 40px;
       border-radius: 18px;
       background: rgba(34, 15, 179, 1);
       color: #FFF;
       text-align: center;
       font-family: Inter;
       font-size: 16px;
       font-style: normal;
       font-weight: 500;
       line-height: normal;
       cursor: pointer;
       display: flex;
       justify-content: center;
       align-items:center;
       letter-spacing:1px;
   }
   .navigation-passive{
       width: 110px;
       height: 40px;
       border-radius: 48px;
       background: transparent;
       color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
display: flex;
justify-content: center;
align-items:center;
letter-spacing:1px;
   }
   #contact{
       width: 110px;
height: 40px;
border-radius: 48px;
background: linear-gradient(180deg, #220FB3 0%, #413CE5 100%);
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
display:flex;
justify-content: center;
align-items: center;
letter-spacing:1px;
   }
}
@media only screen and (min-width:961px){
    #navigation{
         width:100vw;
         margin-top:2.5vh;
        
         display: flex;
         flex-direction: row;
         gap:20vw;
         justify-content: center;
         align-items:center;
         overflow-x:hidden;
    }
    #nav-logo{
        width: 80.002px;
        height: 55.066px;
        flex-shrink: 0;
        cursor: pointer;
    }
    #navigations{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap:4vw;
    }
    .navigation-active{
        width: 120px;
        height: 47px;
        border-radius: 18px;
        background: rgba(34, 15, 179, 1);
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items:center;
        letter-spacing:1px;
    }
    .navigation-passive{
        width: 120px;
        height: 47px;
        border-radius: 18px;
        background: transparent;
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
display: flex;
justify-content: center;
align-items:center;
letter-spacing:1px;
    }
    #contact{
        width: 130px;
height: 47px;
border-radius: 22px;
background: linear-gradient(180deg, #220FB3 0%, #413CE5 100%);
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
display:flex;
justify-content: center;
align-items: center;
letter-spacing:1px;
    }
}
</style>