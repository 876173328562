<template>
    <div id="section-three">
        <h2>Stakeholder Dashboard Interface</h2>
        <h3>The Manage solved visibility and tracebility problem of the FMCG Industry by integrating the following roles.</h3>
        <div id="sec3-badges">
            <div class="sec3-badge">Admin</div>
            <div class="sec3-badge">Manager</div>
            <div class="sec3-badge">Supervisor</div>
            <div class="sec3-badge">Floor Staff</div>
        </div>
        <div class="sec3-button" @click="redirect">Explore More</div>
    </div>
</template>

<script>
import {useRouter} from 'vue-router'
export default {
    setup() {
        const router = useRouter()
        const redirect = ()=>{
            router.push({name: 'featurePage'})
        }
        return {
            redirect
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section-three{
        width:94vw;
        margin-top:6vh;
        margin-left:3vw;
        margin-right:3vw;
        border-radius: 22px;
        border-bottom:none;
        border-top:5px solid #220FB3;
        border-left:2px solid linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
        border-right:2px solid linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
        padding-top:1.5vh;
    }
    #section-three h2{
        color: #FFF;

        text-align: center;
        font-family: sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 37.584px */
        letter-spacing: 0.96px;;
    }
    #section-three h3{
        color: #FDFDFD;

        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 178.571% */
        text-transform: capitalize;
    }
#sec3-badges{
    width:94vw;
    display: grid;
    margin-top:16vh;
    grid-template-columns: repeat(2,44vw);
    grid-gap:4vw;
    margin-top:4.5vh
}
.sec3-badge{
    width:44vw;
    height:12vh;
    border-radius: 10px;
background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
display:flex;
justify-content: center;
align-items: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: 17.793px;  /* 150% */
}
.sec3-button{
    width:70vw;
    height:51px;
    border-radius: 10.747px;
background: linear-gradient(180deg, #2513B7 0%, #4A48F3 122.73%);
box-shadow: 1.653px 1.653px 8.267px 0px rgba(0, 0, 0, 0.15);
display:flex;
justify-content: center;
align-items:center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 26.041px; /* 124.006% */
letter-spacing: 1.89px;
margin-left:12vw;
margin-right:12vw;
margin-top:6vh;
cursor: pointer;
}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section-three{
        width:90vw;
        margin-top:6vh;
        margin-left:5vw;
        margin-right:5vw;
        border-radius: 42px;
        border-bottom:none;
        border-top:5px solid #220FB3;
        border-left:2px solid linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
        border-right:2px solid linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
        padding-top:2vh;
    }
    #section-three h2{
        color: #FFF;
font-family: sans-serif;
font-size: 30.406px;
font-style: normal;
font-weight: 700;
line-height:normal; /* 0px */
letter-spacing: 1.896px;
text-align: center;
    }
    #section-three h3{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 22.815px;
font-style: normal;
font-weight: 400;
line-height: 41.722px; /* 150% */
margin-top:3vh;
    }
#sec3-badges{
    width:90vw;
    display: grid;
    margin-top:4vh;
    grid-template-columns: repeat(4,21vw);
    gap:2vw;
}
.sec3-badge{
    width:21vw;
    height:7vh;
    border-radius: 10px;
background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
display:flex;
justify-content: center;
align-items: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 23.815px;
font-style: normal;
font-weight: 600;
line-height: 41.722px; /* 150% */
}
.sec3-button{
    width:30vw;
    height:65px;
    border-radius: 10.747px;
background: linear-gradient(180deg, #2513B7 0%, #4A48F3 122.73%);
box-shadow: 1.653px 1.653px 8.267px 0px rgba(0, 0, 0, 0.15);
display:flex;
justify-content: center;
align-items:center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 700;
line-height: 26.041px; /* 124.006% */
letter-spacing: 1.89px;
margin-left:30vw;
margin-right:30vw;
margin-top:4vh;
cursor: pointer;
}
}
@media only screen and (min-width:961px){
    #section-three{
        width:82vw;
        margin-top:16vh;
        margin-left:9vw;
        margin-right:9vw;
        border-radius: 42px;
        border-bottom:none;
        border-top:5px solid #220FB3;
        border-left:2px solid linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
        border-right:2px solid linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
        padding-top:5vh;
    }
    #section-three h2{
        color: #FFF;
font-family: sans-serif;
font-size: 47.406px;
font-style: normal;
font-weight: 700;
line-height: 0%; /* 0px */
letter-spacing: 1.896px;
text-align: center;
    }
    #section-three h3{
        color: #FFF;
width:50vw;
margin-left:16vw;
text-align: center;
font-family: Inter;
font-size: 22.815px;
font-style: normal;
font-weight: 400;
line-height: 41.722px; /* 150% */
margin-top:8vh;
    }
#sec3-badges{
    width:78vw;
    padding-left:2vw;
    padding-right:2vw;
    display: grid;
    margin-top:16vh;
    grid-template-columns: repeat(4,18vw);
    gap:1.5vw;
}
.sec3-badge{
    width:18vw;
    height:16vh;
    border-radius: 10px;
background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
display:flex;
justify-content: center;
align-items: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 23.815px;
font-style: normal;
font-weight: 600;
line-height: 41.722px; /* 150% */
}
.sec3-button{
    width:16vw;
    height:65px;
    border-radius: 10.747px;
background: linear-gradient(180deg, #2513B7 0%, #4A48F3 122.73%);
box-shadow: 1.653px 1.653px 8.267px 0px rgba(0, 0, 0, 0.15);
display:flex;
justify-content: center;
align-items:center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: 26.041px; /* 124.006% */
letter-spacing: 1.89px;
margin-left:33vw;
margin-right:33vw;
margin-top:14vh;
cursor: pointer;
}
}
</style>