import { createStore } from 'vuex'

export default createStore({
  state: {
    activePage:1
  },
  getters: {
    getActivePage: (state)=>{
      return state.activePage
    }
  },
  mutations: {
    setActivePage: (state,value: number) =>{
      state.activePage = value
    }
  },
  actions: {
  },
  modules: {
  }
})
