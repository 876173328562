<template>
    <div id="section-two">
        <div id="col-2-1">
            <h2>Get in touch</h2>
            <p>Reach out to us for a personalized consultation and discover how The Manage can revolutionize your supply chain</p>
            <p>Let's discuss how we can assist you in optimizing your supply chain processes and achieving greater efficiency.</p>
        </div>
        <div id="col-2-2">
            <div :class="Error==1?'input-error':'input-container'">
                <img :src="require('../../assets/contact/name.png')" alt="Manage">
                <input type="text" :placeholder="Placeholder1" v-model="name" ref="input-1" @keydown="moveToNextInput($event, 'input-2')">
            </div>
            <div :class="Error==2?'input-error':'input-container'">
                <img :src="require('../../assets/contact/email.png')" alt="Manage">
                <input type="text" :placeholder="Placeholder2" v-model="email" ref="input-2" @keydown="moveToNextInput($event, 'input-3')">
            </div>
            <div :class="Error==3?'input-error':'input-container'">
                <img :src="require('../../assets/contact/phone.png')" alt="Manage">
                <input type="text" :placeholder="Placeholder3" v-model="phone" ref="input-3" @keydown="moveToNextInput($event, 'input-4')">
            </div>
            <div :class="Error==4?'input-error':'input-container'">
                <img :src="require('../../assets/contact/requirement.png')" alt="Manage">
                <input type="text" :placeholder="Placeholder4" v-model="requirement" ref="input-4">
            </div>
            <div class="sec2-button" @click="submit1">Send</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            name: '',
            email: '',
            phone: '',
            requirement: '',
            errorField:0,
            placeholder1:'Name',
            placeholder2:'Enter your E-mail',
            placeholder3:'Enter your Phone number',
            placeholder4:'Requirements'

        }
    },
    computed:{
        Placeholder1(){
            return this.placeholder1
        },
        Placeholder2(){
            return this.placeholder2
        },
        Placeholder3(){
            return this.placeholder3
        },
        Placeholder4(){
            return this.placeholder4
        },
        Error(){
            return this.errorField
        }
    },
    methods:{
        submit1(){
            // add all the value checks here
            console.log('submit')
        },
        moveToNextInput(event, nextInputName) {
      if (event.key === 'Enter') {
        this.$refs[nextInputName].focus();
      }
    }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@media only screen and (max-width:480px){
    #section-two{
        width:94vw;
        margin-left:3vw;
        margin-right:3vw;
        margin-top:-9vh;
    }
    #col-2-1{
        width:94vw;
    }
    #col-2-1 h2{
        color: #FFF;

text-align: center;
font-family: Anek Latin;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 40.716px */
letter-spacing: 1.04px;
    }
    #col-2-1 p{
        color: #FDFDFD;

        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 168.75% */
        text-transform: capitalize;
    }
    #col-2-2{
        padding-top:1vh;
        margin-top:1.25vh;
        width:94vw;
    }
    .input-container{
        width:94vw;
        border-radius: 20px;
        background: #F8F9FB;
        height:8vh;
        margin-top:3vh;
        margin-bottom:3vh;
        display:flex;
        flex-direction: row;
        align-items: center;
        overflow:hidden;
    }
    .input-error{
        width:94vw;
        border-radius: 20px;
        background: #F8F9FB;
        height:8vh;
        margin-top:3vh;
        margin-bottom:3vh;
        display:flex;
        flex-direction: row;
        align-items: center;
        overflow:hidden;
        border:1px solid red;
    }
    .input-container img,
    .input-error img{
    width: 26px;
height: 26px;
margin-left:2vw;
    }
    .input-container input,
    .input-container input:focus,
    .input-container input::selection,
    .input-error input,
    .input-error input:focus,
    .input-error input::selection{
        margin-top:0px;
        margin-bottom:0px;
        border:none;
        outline:none;
        background: transparent;
        height:8vh;
        width:88vw;
        margin-left:1.75vw;
        color: #282929;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .input-container input::placeholder{
        color: #6E7474;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .input-error input::placeholder{
        color: rgba(255, 0, 0, 0.67);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .sec2-button{
        width:50vw;
        height:45px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
text-align: center;
font-family: Quicksand;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 31.5px; /* 150% */
border-radius: 38.5px;
background: #2613B7;
float: right;
margin-right:2vw;
margin-top:1.75vh;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section-two{
        width:90vw;
        margin-left:5vw;
        margin-right:5vw;
        margin-top:-9vh;
    }
    #col-2-1{
        width:90vw;
    }
    #col-2-1 h2{
        color: #FFF;
text-align: center;
font-family: Anek Latin;
font-size: 27px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 40.716px */
letter-spacing: 1.04px;
    }
    #col-2-1 p{
        color: #FDFDFD;
width:70vw;
margin-left:10vw;
text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 168.75% */
        text-transform: capitalize;
    }
    #col-2-2{
        padding-top:1vh;
        margin-top:.25vh;
        width:94vw;
    }
    .input-container{
        width:70vw;
        border-radius: 20px;
        background: #F8F9FB;
        height:5vh;
        margin-top:2vh;
        margin-bottom:2vh;
        display:flex;
        flex-direction: row;
        align-items: center;
        overflow:hidden;
        margin-left:10vw;
        margin-right:10vw;
    }
    .input-error{
        width:70vw;
        border-radius: 20px;
        background: #F8F9FB;
        height:5vh;
        margin-top:1.5vh;
        margin-bottom:1.5vh;
        display:flex;
        flex-direction: row;
        align-items: center;
        overflow:hidden;
        margin-left:10vw;
        margin-right:10vw;
        border:1px solid red;
    }
    .input-container img,
    .input-error img{
    width: 36px;
height: 36px;
margin-left:2vw;
    }
    .input-container input,
    .input-container input:focus,
    .input-container input::selection,
    .input-error input,
    .input-error input:focus,
    .input-error input::selection{
        margin-top:0px;
        margin-bottom:0px;
        border:none;
        outline:none;
        background: transparent;
        height:5vh;
        width:59vw;
        margin-left:1.75vw;
        color: #282929;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .input-container input::placeholder{
        color: #6E7474;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .input-error input::placeholder{
        color: rgba(255, 0, 0, 0.67);
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .sec2-button{
        width:40vw;
        height:55px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
text-align: center;
font-family: Quicksand;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: 31.5px; /* 150% */
border-radius: 38.5px;
background: #2613B7;
margin-left:25vw;
margin-right:25vw;
margin-top:2.35vh;
    }
}
@media only screen and (min-width:961px){
    #section-two{
        width:82vw;
        margin-left:9vw;
        margin-right:9vw;
        margin-top:-30vh;
        display:grid;
        grid-template-columns: 36vw 40vw;
        gap:6vw;
    }
    #col-2-1{
        width:36vw;
    }
    #col-2-1 h2{
        color: #FFF;
font-family: Inter;
font-size: 48.483px;
font-style: normal;
font-weight: 700;
line-height: 125.269px; /* 258.375% */
letter-spacing: 1.825px;
margin-bottom:-3vh;
    }
    #col-2-1 p{
        color: #FFF;
font-family: Inter;
font-size:20.815px;
font-style: normal;
font-weight: 400;
line-height: 31.722px; /* 150% */
margin-top:3vh;
margin-bottom:3vh;
    }
    #col-2-2{
        padding-top:7.5vh;
        margin-left:4vw;
    }
    .input-container{
        width:40vw;
        border-radius: 61px;
        background: #F8F9FB;
        height:8vh;
        margin-top:5vh;
        margin-bottom:5vh;
        display:flex;
        flex-direction: row;
        align-items: center;
        overflow:hidden;
    }
    .input-error{
        width:40vw;
        border-radius: 61px;
        background: #F8F9FB;
        height:8vh;
        margin-top:5vh;
        margin-bottom:5vh;
        display:flex;
        flex-direction: row;
        align-items: center;
        overflow:hidden;
        border:1px solid red;
    }
    .input-container img,
    .input-error img{
    width: 32px;
height: 32px;
margin-left:1.5vw;
    }
    .input-container input,
    .input-container input:focus,
    .input-container input::selection,
    .input-error input,
    .input-error input:focus,
    .input-error input::selection{
        margin-top:0px;
        margin-bottom:0px;
        border:none;
        outline:none;
        background: transparent;
        height:8vh;
        width:33vw;
        margin-left:1vw;
        color: #282929;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .input-container input::placeholder{
        color: #6E7474;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .input-error input::placeholder{
        color: rgba(255, 0, 0, 0.67);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 41.71px; /* 231.722% */
    }
    .sec2-button{
        width:12vw;
        height:51px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
text-align: center;
font-family: Quicksand;
font-size: 21px;
font-style: normal;
font-weight: 700;
line-height: 31.5px; /* 150% */
cursor: pointer;
border-radius: 38.5px;
background: #2613B7;
float: right;
margin-right:-4vw;
    }
}
</style>