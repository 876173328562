<template>
    <div id="section-four">
        <h5>Supply Chain Industries</h5>
        <div class="sec4-1">
            <div class="sec4-content">
                <h2>Fast Moving Consumer Goods</h2>
                <p>Elevate your FMCG supply chain with The Manage's innovative solutions. Ensure real-time logistics tracking, demand forecasting, and end-to-end visibility to meet the dynamic demands of the fast-paced FMCG industry.</p>
                
            </div>
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-one.png')" alt="Managed">
            </div>
        </div>
        <div class="sec4-1 reverse">
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-two.png')" alt="Manage">
            </div>
            <div class="sec4-content">
                <h2>Automotive</h2>
                <p>Transform your automotive supply chain with The Manage's blockchain solutions. Streamline logistics, enhance compliance, and ensure traceability for a seamless manufacturing process in the automotive sector.</p>
            </div>
          
        </div>
        <div class="sec4-1">
            <div class="sec4-content">
                <h2>Healthcare</h2>
                <p>In the healthcare sector, The Manage provides end-to-end visibility, secure data exchange, and asset tracking, ensuring the integrity of the pharmaceutical supply chain and healthcare operations.</p>
            </div>
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-three.png')" alt="Manage">
            </div>
        </div>
        <div class="sec4-1 reverse">
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-four.png')" alt="Manage">
            </div>
            <div class="sec4-content">
                <h2>Retail</h2>
                <p>Optimize your retail supply chain with The Manage. From real-time monitoring to automated order fulfillment, our solutions deliver enhanced transparency and efficiency, providing a superior customer experience.</p>
            </div>
            
        </div>
        <div class="sec4-1">
            <div class="sec4-content">
                <h2>Manufacturing</h2>
                <p>In manufacturing, enhance efficiency and collaboration with The Manage. Our tailored solutions for inventory optimization, demand forecasting, and process automation ensure streamlined operations.</p>
            </div>
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-five.png')" alt="Manage">
            </div>
        </div>
        <div class="sec4-1 reverse">
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-six.png')" alt="Manage">
            </div>
            <div class="sec4-content">
                <h2>Logistics</h2>
                <p>Streamline logistics management with The Manage's scalable blockchain solutions. From real-time tracking to vendor-managed inventory, we empower logistics providers for success in a dynamic market.</p>
            </div>
          
        </div>
        <div class="sec4-1">
            <div class="sec4-content">
                <h2>Pharmaceuticals</h2>
                <p>Ensure traceability in the pharmaceutical supply chain with The Manage's solutions. From compliance automation to real-time monitoring, we safeguard the integrity of pharmaceuticals, ensuring patient safety.</p>
            </div>
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-seven.png')" alt="Manage">
            </div>
        </div>
        <div class="sec4-1 reverse">
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-eight.png')" alt="Manage">
            </div>
            <div class="sec4-content">
                <h2>Energy</h2>
                <p>Optimize energy supply chains with The Manage's scalable solutions. Providing transparency, secure data exchange, and efficient procurement automation, we support the energy sector's evolving needs.</p>
            </div>
            
        </div>
        <div class="sec4-1">
            <div class="sec4-content">
                <h2>Agriculture</h2>
                  <p>Revolutionize the agriculture supply chain with blockchain-driven innovation. The Manage supports ethical agricultural practices, offering solutions for sustainable sourcing and transparent transactions.</p>
            </div>
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-nine.png')" alt="Manage">
            </div>
        </div>
        <div class="sec4-1 reverse">
            <div class="sec4-image">
                <img :src="require('../../assets/about/images/image-ten.png')" alt="Manage">
            </div>
            <div class="sec4-content">
                <h2>Technology</h2>
                <p>In the technology sector, authenticate components with The Manage's secure and immutable ledger. Our solutions ensure the traceability and integrity of technology hardware, addressing key challenges in the industry.</p>
            </div>
            
        </div>
        <h3 id="sec4-end">We provide tailored solutions to your particular supply chain problems across industries</h3>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section-four{
        width: 94vw;
        margin-left:3vw;
        margin-right:3vw;
        margin-top:10vh;
    }
    #section-four h5{
        color: #FFF;

font-family: sans-serif;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 40.716px */
letter-spacing: 1.04px;
text-align:center
    }
    .sec4-1{
        width:94vw;
        margin-top:2.5vh;
        margin-bottom:2.5vh;
    }
    .reverse{
        display: flex;
        flex-direction: column-reverse;
    }
    .sec4-image{
        width:94vw;
    }
    .sec4-image img{
        width:94vw;
        height:70%;
        border-radius: 10px;
    }
    .sec4-content{
        width:94vw
    }
    .sec4-content h2{
        color: #FFF;

font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 31.32px */
letter-spacing: 0.8px;
    }
    .sec4-content p{
        color: #FFF;

        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 150% */
    }
    #sec4-end{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 31.722px;
        margin-top:6vh; 
        width:80vw;
        margin-left:7vw;
        margin-right:7vw;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section-four{
        width: 90vw;
        margin-left:5vw;
        margin-right:5vw;
        margin-top:4vh;
    }
    #section-four h5{
        color: #FFF;

font-family: sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 40.716px */
letter-spacing: 1.04px;
text-align:center
    }
    .sec4-1{
        width:90vw;
        margin-top:2vh;
        margin-bottom:2vh;
    }
    .reverse{
        display: flex;
        flex-direction: column-reverse;
    }
    .sec4-image{
        width:90vw;
    }
    .sec4-image img{
        width:90vw;
        height:50%;
        border-radius: 10px;
    }
    .sec4-content{
        width:90vw
    }
    .sec4-content h2{
        color: #FFF;

font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 31.32px */
letter-spacing: 0.8px;
    }
    .sec4-content p{
        color: #FFF;

        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px; /* 150% */
    }
    #sec4-end{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 31.722px;
        margin-top:6vh; 
        width:70vw;
        margin-left:10vw;
        margin-right:10vw;
    }
}
@media only screen and (min-width:961px){
    #section-four{
        width: 100vw;
        margin-top:14vh;
    }
    #section-four h5{
        color: #FFF;
        font-family: sans-serif;
        font-size: 37.406px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 74.238px */
        letter-spacing: 1.896px;
        text-align:center
    }
    .sec4-1{
        width:82vw;
        padding-left:9vw;
        padding-right:9vw;
        display:grid;
        grid-template-columns: repeat(2,40vw);
        gap:4vw;
        margin-top:10vh;
        margin-bottom:10vh;
    }
    .sec4-image{
        width:40vw;
    }
    .sec4-image img{
        width:40vw;
        height:35vh;
        border-radius: 10px;
    }
    .sec4-content{
        width:40vw
    }
    .sec4-content h2{
        color: #FFF;
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 56.376px */
letter-spacing: 1.44px;
margin-top:0px;
    }
    .sec4-content p{
        color: #FFF;
font-family: Inter;
font-size: 19.815px;
font-style: normal;
font-weight: 400;
line-height: 32.722px; /* 150% */
    }
    .space{
        margin-top:9vh;
        margin-bottom:4vh;
    }
    #sec4-end{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 21.815px;
font-style: normal;
font-weight: 600;
line-height: 41.722px; /* 150% */
width:30vw;
margin-left:35vw;
margin-top:15vh;
    }
}
</style>