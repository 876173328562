<template>
   <div>
    <swiper
    :spaceBetween="30"
    :autoplay="{
      delay: 1500,
      disableOnInteraction: false,
    }"
    :loop="true"
    :slides-per-view="3"
    :modules="modules"
    id="section2">

    <swiper-slide class='sec2-badge'>
        <h2>Customization Excellence</h2>
            <h3>Collaborating closely with our clients, we understand their unique supply chain challenges, tailoring and building solutions that precisely align with their specific needs.</h3>
      </swiper-slide>
      <swiper-slide class='sec2-badge'>
        <h2>Intelligent Insights</h2>
            <h3>Our intelligent solution harnesses data to provide actionable insights, addressing and resolving the exact issues faced by the client for a smarter and more informed supply chain.</h3>
      </swiper-slide>
      <swiper-slide class='sec2-badge'>
        <h2>Effortless User Experience</h2>
            <h3>Designed with diverse stakeholders in mind, our user-friendly dashboards empower clients with easy navigation and enhanced control over their supply chain, ensuring a seamless experience.</h3>
      </swiper-slide>
      <swiper-slide class='sec2-badge'>
        <h2>Scalability at Its Core</h2>
            <h3>Adapting to evolving client needs, we offer highly scalable solutions that grow with our client’s business, ensuring long-term success in an ever-changing business landscape.</h3>
      </swiper-slide>

  </swiper>
   </div>
  </template>
  <script>
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
  
    // Import Swiper styles
    import 'swiper/css';
    // import required modules
    import { Autoplay} from 'swiper/modules';
  
    export default {
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        return {
          modules: [Autoplay],
        };
      },
    };
  </script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
  #section2{
    display: none;
  }
}
@media only screen and (min-width:481px) and (max-width:960px){
  #section2{
    display: none;
  }
}
@media only screen and (min-width:961px){
    #section2{
        width:82vw;
        margin-left:9vw;
        margin-right:9vw;
        overflow:hidden;
        display:grid;
        grid-template-columns: repeat(4,24vw);
        gap:3.5vw;
        margin-top:14vh;
        overflow-x:auto;
    }
    #section2::-webkit-scrollbar{
        display:none;
    }
    .sec2-badge{
        width:22vw;
        height:32vh;
        overflow:hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
padding-left:1vw;
padding-right:1vw;
    }
    .sec2-badge h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 23.5px;
font-style: normal;
font-weight: 600;
line-height:normal;
    }
    .sec2-badge h3{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:0;
width:20vw;
    }
}
</style>
  