<template>
    <div id="section-three">
        <h2>Our Trusted Partners</h2>
        <div id="partners">
           <img :src="require('../../assets/about/companies/rywards.png')" alt="Rywards" class="partner">
           <img :src="require('../../assets/about/companies/decrackle.png')" alt="Decrackle" class="partner">
           <!-- <img :src="require('../../assets/about/companies/5ire.png')" alt="5ire" class="partner"> -->
           <img :src="require('../../assets/about/companies/sustainogram.png')" alt="Sustainogram" class="partner">
           <!-- <img :src="require('../../assets/about/companies/artfi.png')" alt="Artfi" class="partner"> -->
           <img :src="require('../../assets/about/companies/mundum.png')" alt="MUNDUM" class="partner">
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section-three{
        margin-top:7vh;
        width:94vw;
        margin-left:3vw;
        margin-right:3vw;
    }
    #section-three h2{
        color: #FFF;
text-align: center;
        font-family: sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 40.716px */
        letter-spacing: 1.04px;
    }
    #partners{
        width:94vw;
        overflow-x:hidden ;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap:6vw;
    }
    #partners::-webkit-scrollbar{
        display: none;
    }
    .partner{
        width: 40vw;
        height: 5.75vh;
        margin-top:1.75vh;
        margin-bottom:1.75vh
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section-three{
        margin-top:6vh;
        width:90vw;
        margin-left:5vw;
        margin-right:5vw;
    }
    #section-three h2{
        color: #FFF;
text-align: center;
        font-family: sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 40.716px */
        letter-spacing: 1.04px;
    }
    #partners{
        width:90vw;
        overflow-x:hidden ;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    #partners::-webkit-scrollbar{
        display: none;
    }
    .partner{
        width: 30vw;
        height: 5.75vh;
        margin-top:2vh;
        margin-bottom:2vh;
        margin-left:5vw;
        margin-right:5vw;
    }
}
@media only screen and (min-width:961px){
    #section-three{
        margin-top:15vh;
        width:100vw;
    }
    #section-three h2{
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 50.112px */
letter-spacing: 1.28px;
    }
    #partners{
        width:90vw;
        padding-left:5vw;
        padding-right:5vw;
        overflow-x:auto ;
        display: flex;
        flex-direction: row;
        gap:3vw;
        justify-content: center;
        padding-top:5vh;
    }
    #partners::-webkit-scrollbar{
        display: none;
    }
    .partner{
        width: 12vw;
        height: 6vh
    }
}
</style>