<template>
    <div id="mobile-nav">
       <div v-if="Toggle">
        <div id="nav-open">
            <img :src="require('../../assets/general/icon.png')" alt="Manage"  @click="setPage('landingPage')">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" @click="handleToggle(false)">
                <path d="M1 1L21 21M1 21L21 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
        </div> 
        <div id="nav-menu">
               <h2  @click="setPage('landingPage')">HOME</h2>
               <div id="nav-divider"></div>
               <h2  @click="setPage('aboutPage')">ABOUT US</h2>
               <div id="nav-divider"></div>
               <h2  @click="setPage('productPage')">PRODUCTS</h2>
        </div>
        <div id="nav-connect" @click="setPage('contactPage')">Connect</div>
       </div>
        <div id="nav-close" v-else> 
            <img :src="require('../../assets/general/icon.png')" alt="Manage"  @click="setPage('landingPage')">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="23" viewBox="0 0 31 23" fill="none" @click="handleToggle(true)">
                <path d="M30 1.80469L1 1.80469" stroke="white" stroke-width="1.7" stroke-linecap="round"/>
                <path d="M30 11.8047L13.2105 11.8047" stroke="white" stroke-width="1.7" stroke-linecap="round"/>
                <path d="M30 21.8047L20.8421 21.8047" stroke="white" stroke-width="1.7" stroke-linecap="round"/>
              </svg>
        </div>
        
    </div>
</template>

<script>
import {ref,computed} from 'vue'
import {useRouter} from 'vue-router'
export default {
    setup() {
        const toggle = ref(false)
        const router = useRouter()
        const Toggle = computed(()=>{
            return toggle.value
        })
        const handleToggle = (value)=>{
            toggle.value = value
        }
        const setPage = (page)=>{
            router.push({name:page})
    }
        return {
            Toggle,
            handleToggle,
            setPage
        }
    },
}
</script>

<style scoped>
@media only screen and (max-width:480px){
    #mobile-nav{
        width:100vw;
        padding-top:3vh;
        z-index: 1;
    }
    #nav-close,
    #nav-open{
        width:94vw;
        display: flex;
        padding-left:3vw;
        padding-right:3vw;
        gap:69vw;
        transition: all 0.5s ease-in-out;
    }
    #nav-menu{
        width:94vw;
        padding-left:5vw;
        padding-right:3vw;
        margin-top:3.5vh;
        transition: all 0.5s ease-in-out;
    }
    #nav-close img,
    #nav-open img{
        width: 54.998px;
height: 37.611px;
    }
    #nav-close svg,
    #nav-open svg{
     margin-top:1vh;
    }
    #nav-menu h2{
        color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 17.266px; /* 123.326% */
letter-spacing: 0.247px;
text-transform: uppercase;
margin-left:0.35vw;
margin-bottom:0.75vh;
    }
    #nav-divider{
        background: #2626AF;
        height:2px;
        width:80vw;
        margin-top:1.75vh;
        margin-bottom:1.75vh;

    }
    #nav-connect{
        width:80vw;
        margin-left:10vw;
        margin-right:10vw;
        margin-top:4.5vh;
        height:5.75vh;
        border-radius: 48px;
background: linear-gradient(180deg, #220FB3 0%, #413CE5 100%);
display: flex;
justify-content: center;
align-items:center;
        color: #FFF;
text-align: center;
font-family: Inter;
font-size: 17.995px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #mobile-nav{
        display: none;
    }
}
@media only screen and (min-width:961px){
    #mobile-nav{
        display: none;
    }
}
</style>