<template>
    <div id="section-two">
        <h2>{{Text}}</h2>
        <div id="badges">
            <div :class="Index==0?'badge-active':'badge-passive'"
             @mouseover="toggleRotate(0)"
            @mouseleave="toggleRotate(0)"
            >Seamless System Integration</div>
            <div :class="Index==1?'badge-active':'badge-passive'"
             @mouseover="toggleRotate(1)"
            @mouseleave="toggleRotate(1)"
            >Effortless Onboarding Experience</div>
            <div :class="Index==2?'badge-active':'badge-passive'"
             @mouseover="toggleRotate(2)"
            @mouseleave="toggleRotate(2)"
            >Database Sync Simplified</div>
            <div :class="Index==3?'badge-active':'badge-passive'"
             @mouseover="toggleRotate(3)"
            @mouseleave="toggleRotate(3)"
            >Tailored Integration Approach</div>
        </div>
        <p class="sec2-para">
            {{ Paragraph }}
        </p>
    </div>
</template>
<script>
import {ref,computed,onMounted} from 'vue'
export default {
    setup() {
        const index = ref(0);
        const breakRotate = ref(false)
        const para = ref(["At The Managed, we don't just follow industry standards; we redefine them. Our journey is marked by a relentless pursuit of innovation, a commitment to excellence, and a passion for transforming supply chain dynamics. Dive into a world where technology meets precision, and operations are elevated to new heights.",
                          "In the heart of our operations lies a commitment to redefine technology's role in supply chain management. From leveraging private blockchain security to seamless Hyperledger integration, we create solutions that go beyond expectations. Experience the power of a technological revolution at every click.",
                          "Achieve effortless database synchronization. The Manage seamlessly integrates with your existing systems, optimizing data flow for streamlined operations.",
                          "Embrace a customized integration journey with The Manage. We adapt to your unique setup, ensuring a tailored and efficient assimilation process."])
        const Index = computed(()=>{
            return index.value
        })
        const Paragraph = computed(()=>{
            return para.value[index.value]
        })
        const Text = computed(()=>{
            switch(index.value){
                case 0:
                    return "Seamless System Integration"
                case 1:
                    return "Effortless Onboarding Experience"
                case 2:
                    return "Database Sync Simplified"
                case 3:
                    return "Tailored Integration Approach"
                default:
                    return "Seamless System Integration"
            }
        })
        const Rotate = ()=>{
          
                try{
                setTimeout(()=>{
                    if(breakRotate.value==false){
                        if(index.value==3) index.value=0
                    else index.value++
                    Rotate()
                    }
                    
                },2000)
            }
            catch(err){
                console.log('')
            }
            
        }
        function toggleRotate(val){
            breakRotate.value=!breakRotate.value
            index.value = val
            if(breakRotate.value==false){
                Rotate()
            }
        }
        onMounted(()=>{
            Rotate()
        })
        return{
            Index,
            Paragraph,
            toggleRotate,
            Text
        }
    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section-two{
        width:94vw;
        margin-left:3vw;
        margin-right:3vw;
      margin-top:2vh;
    }
    #section-two h2{
        color: #FFF;
         text-align: center;
        font-family: sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 37.584px */
        letter-spacing: 0.96px;
        transition: all 0.5s ease-in-out;
    }
    #badges{
        width:94vw;
        display:grid;
        grid-template-columns: repeat(2,47vw);
        grid-gap:2vw;
        margin-top:3.5vh;
    }
    .badge-active{
        border-radius: 17px;
background: #373AFF;
width:44vw;
height:5.75vh;
display:flex;
justify-content: center;
align-items:center;
color: #FFF;
font-family: Inter;
font-size: 10.5px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 14.094px */
letter-spacing: 0.36px;
padding-left:1vw;
padding-right:1vw;
padding-top:0.5vh;
padding-bottom:0.5vh;
overflow:hidden;
margin-top:1vh;
margin-bottom:1vh;
transition: all 0.5s ease-in-out;
    }
    .badge-passive{
        border-radius: 17px;
background: #FFF;
width:44vw;
height:5.75vh;
display:flex;
justify-content: center;
align-items:center;
color: #373AFF;
font-family: Inter;
font-size: 10.5px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 14.094px */
letter-spacing: 0.36px;
padding-left:1vw;
padding-right:1vw;
padding-top:0.5vh;
padding-bottom: 0.5vh;
overflow: hidden;
margin-top:1vh;
margin-bottom:1vh;
transition: all 0.5s ease-in-out;
    }
.sec2-para{
    color: #FDFDFD;

    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 168.75% */
    text-transform: capitalize;
    margin-top:4vh;
    transition: all 0.5s ease-in-out;

}
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section-two{
        width:90vw;
        margin-left:5vw;
        margin-right:5vw;
      margin-top:2.5vh;
    }
    #section-two h2{
        color: #FFF;
         text-align: center;
        font-family: sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 37.584px */
        letter-spacing: 0.96px;
        transition: all 0.5s ease-in-out;
    }
    #badges{
        width:90vw;
        display:grid;
        grid-template-columns: repeat(2,40vw);
        margin-top:3.75vh;
        grid-gap:4vw;
        padding-left:3vw;
        padding-right:3vw;
    }
    .badge-active{
        border-radius: 17px;
background: #373AFF;
width:40vw;
height:4.75vh;
display:flex;
justify-content: center;
align-items:center;
color: #FFF;
font-family: Inter;
font-size: 18.5px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 14.094px */
letter-spacing: 0.36px;
padding-left:1vw;
padding-right:1vw;
padding-top:0.5vh;
padding-bottom:0.5vh;
overflow:hidden;
transition: all 0.5s ease-in-out;
    }
    .badge-passive{
        border-radius: 17px;
background: #FFF;
width:40vw;
height:4.75vh;
display:flex;
justify-content: center;
align-items:center;
color: #373AFF;
font-family: Inter;
font-size: 18.5px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 14.094px */
letter-spacing: 0.36px;
padding-left:1vw;
padding-right:1vw;
padding-top:0.5vh;
padding-bottom: 0.5vh;
overflow: hidden;
transition: all 0.5s ease-in-out;
    }
.sec2-para{
    color: #FDFDFD;

    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 168.75% */
    text-transform: capitalize;
    margin-top:2vh;
    transition: all 0.5s ease-in-out;

}
}
@media only screen and (min-width:961px){
    #section-two{
        width:100vw;
      margin-top:-5vh;
    }
    #section-two h2{
        margin-left:5vw;
        color: #FFF;
font-family: Inter;
font-size: 37.406px;
font-style: normal;
font-weight: 700;
line-height: 156.6%; /* 74.238px */
letter-spacing: 1.896px;
transition: all 0.5s ease-in-out;
    }
    #badges{
        margin-left:5vw;
        display:flex;
        flex-direction: row;
        gap:2vw;
        margin-top:3vh;
    }
    .badge-active{
        border-radius: 29.247px;
background: #373AFF;
width:18vw;
height:5vh;
display:flex;
justify-content: center;
align-items:center;
color: #FFF;
font-family: Inter;
font-size: 15.393px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 24.106px */
letter-spacing: 0.616px;
padding-left:1vw;
padding-right:1vw;
padding-top:0.5vh;
padding-bottom:0.5vh;
overflow:hidden;
transition: all 0.5s ease-in-out;
    }
    .badge-passive{
        border-radius: 29.247px;
background: #FFF;
width:18vw;
height:5vh;
display:flex;
justify-content: center;
align-items:center;
color: #373AFF;
font-family: Inter;
font-size: 15.393px;
font-style: normal;
font-weight: 600;
line-height: 156.6%; /* 24.106px */
letter-spacing: 0.616px;
padding-left:1vw;
padding-right:1vw;
padding-top:0.5vh;
padding-bottom: 0.5vh;
overflow: hidden;
transition: all 0.5s ease-in-out;
    }
.sec2-para{
    margin-left:5.5vw;
    max-width:74vw;
    color: #FFF;
font-family: Inter;
font-size: 18.815px;
font-style: normal;
font-weight: 400;
line-height: 31.722px; /* 150% */
transition: all 0.5s ease-in-out;

}
}
</style>