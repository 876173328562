<template>
    <div id="section4">
        <div id="sec4-1">
            <h3>Empowering Supply Chain Management with Role-based Precision</h3>
            <p>Experience a paradigm shift in supply chain control as The Manage introduces role-based precision, leveraging blockchain synergy for optimized operations and unparalleled stakeholder engagement. Unlock the potential of tailored control and precision in your supply chain management.</p>
            <img :src="require('../../assets/landing/images/image-one.png')" alt="Managed">
        </div>
        <div id="sec4-2">
            <div class="sec4-content">
                <h2>Process</h2>
                <p>Embark on a collaborative journey with The Manage, where our solution is meticulously tailored to your supply chain needs. Our process begins with a deep dive into your unique challenges, working hand-in-hand to understand your operations intricately.</p>
                <p>Through insightful collaboration, we craft a tailored solution that goes beyond generic fixes. The Manage ensures that your supply chain solution aligns seamlessly with your business objectives.</p>
            </div>
            <div class="sec4-img">
                <img :src="require('../../assets/landing/images/image-two.png')" alt="Managed">
            </div>
        </div>
        <div id="sec4-3">
            <div class="sec4-img">
                <img :src="require('../../assets/landing/images/image-three.png')" alt="Managed">
            </div>
            <div class="sec4-content">
                <h2>Sustainable Sourcing with Enhanced Product Tracking</h2>
                <p>Empower your sustainability journey with The Manage's enhanced traceability where we have a sustainable supply chain policy. </p>
                <p>Gain insights into your carbon consumption and look forward to our upcoming carbon visibility feature for a comprehensive approach to sustainability.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section4{
        width:94vw;
        margin-top:5vh;
        display:block;
        overflow: hidden;
        margin-left:3vw;
        margin-right:3vw;
    }
    #sec4-1{
        width:94vw;
        margin-bottom:10.5vh;
    }
    #sec4-1 h3{
        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 156.6%; /* 25.056px */
        letter-spacing: 0.64px;
    }
    #sec4-1 p{
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 168.75% */
    }
    #sec4-1 img{
        width:94vw;
        margin-top:3.5vh;
        height:70%;
    }
    #sec4-2{
        width:94vw;
        margin-top:5vh;
    }
    #sec4-3{
        width:94vw;
        margin-top:5vh;
        display:flex;
        flex-direction: column-reverse;
    }
    .sec4-img{
        width:94vw;
    }
    .sec4-img img{
        width:94vw;
        height:70%;
    }
    .sec4-content{
        width:94vw;
    }
    .sec4-content h2{
        color: #FFF;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 25.056px */
        letter-spacing: 0.64px;
    }
    .sec4-content p{
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px; /* 168.75% */
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section4{
        width:90vw;
        margin-top:4vh;
        display:block;
        overflow: hidden;
        margin-left:5vw;
        margin-right:5vw;
    }
    #sec4-1{
        width:90vw;
        margin-bottom:7.5vh;
    }
    #sec4-1 h3{
        color: #FFF;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 156.6%; /* 25.056px */
        letter-spacing: 0.64px;
    }
    #sec4-1 p{
        color: #ffffffe3;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 168.75% */
    }
    #sec4-1 img{
        width:90vw;
        margin-top:2vh;
        height:50%;
    }
    #sec4-2{
        width:90vw;
        margin-top:2vh;
    }
    #sec4-3{
        width:90vw;
        margin-top:2vh;
        display:flex;
        flex-direction: column-reverse;
    }
    .sec4-img{
        width:90vw;
    }
    .sec4-img img{
        width:90vw;
        height: 38vh;
    }
    .sec4-content{
        width:90vw;
    }
    .sec4-content h2{
        color: #FFF;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 156.6%; /* 25.056px */
        letter-spacing: 0.64px;
    }
    .sec4-content p{
        color: #ffffffe7;
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px; /* 168.75% */
    }
}
@media only screen and (min-width:961px){
    #section4{
        width:100vw;
        margin-top:12vh;
        display:block;
        overflow: hidden;
    }
    #sec4-1{
        width:82vw;
        margin-bottom:10.5vh;
    }
    #sec4-1 h2{
        color: #4749E7;
font-family: Inter;
font-size: 28px;
font-style: normal;
font-weight: bolder;
line-height:normal;
letter-spacing: 1.28px;
margin-left:9vw;
margin-bottom:3vh;
    }
    #sec4-1 h3{
        color: #FFF;
font-family: Inter;
font-size: 34.406px;
font-style: normal;
font-weight: bold;
line-height: 156.6%; /* 74.238px */
letter-spacing: 1.896px;
margin-left:9vw;
margin-top:0;
margin-bottom:5vh;
    }
    #sec4-1 p{
        color: #ffffffd0;
font-family: Inter;
font-size: 23px;
font-style: normal;
font-weight: 300;
line-height: 31px;
margin-left:9vw;
margin-top:0;
width:65vw;
    }
    #sec4-1 img{
        margin-left:9vw;
        margin-right:9vw;
        width:82vw;
        margin-top:5vh;
        height:70%;
        margin-bottom:3vh;
    }
    #sec4-2,
    #sec4-3{
        width:82vw;
        padding-left:9vw;
        padding-right:9vw;
        display: grid;
        grid-template-columns: repeat(2,40vw);
        gap:2vw;
        margin-top:10vh;
    }
    .sec4-img{
        width:40vw;
    }
    .sec4-img img{
        width:40vw;
        height:50vh;
    }
    .sec4-content{
        width:40vw;
    }
    .sec4-content h2{
        color: #FFF;
font-family: Sans-Serif;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal; /* 0px */
letter-spacing: 1.44px;
    }
    .sec4-content p{
        color: rgba(255, 255, 255, 0.822);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30.722px;
    }
}
</style>