<template>
    <div id="animation-badge">
        <img :src="require('../../assets/general/badges/badge-1.png')" :alt="h2" v-if="img==0">
        <img :src="require('../../assets/general/badges/badge-3.png')" :alt="h2" v-if="img==1">
        <img :src="require('../../assets/general/badges/badge-2.png')" :alt="h2" v-if="img==2">
        <img :src="require('../../assets/general/badges/badge-4.png')" :alt="h2" v-if="img==3">
        <div id='badge-text'>
            <h2>{{ h2 }}</h2>
            <h3>{{ h3 }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    props:['img','h2','h3']
}
</script>

<style scoped>
@media only screen and (max-width:480px){}
@media only screen and (min-width:481px) and (max-width:960px){}
@media only screen and (min-width:961px){
    #animation-badge{
        width:15vw;
        height:8vh;
        border-radius:16px;
        background: #FFFEFF;
        display:flex;
        flex-direction:row;
        overflow: hidden;
        align-items: center;
    }
    #animation-badge img{
        margin-left:0.25vw;
        margin-right:0.25vw;
        width:45px;
        height:45px;
    }
    #badge-text h2{
        color: #232740;
        font-family: Roboto;
        font-size: 16.482px;
        font-style: normal;
        font-weight: 600;
        line-height: 24.723px; /* 150% */
        margin-left:0;
        margin-bottom:0;
        word-wrap: break-word;
    }
    #badge-text h3{
        color: #6B6E81;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24.723px; /* 176.595% */
        margin-left:0;
        margin-top:0;
        word-wrap: break-word;
    }
}
</style>