<template>
    <div id="section-two">
      <div id="sec2-badges">
        <div class="sec2-badge">Admin</div>
        <div class="sec2-badge">Manager</div>
        <div class="sec2-badge">Supervisor</div>
        <div class="sec2-badge">Floor Staff</div>
      </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #section-two{
        width:94vw;
        margin-top:3vh;
        margin-bottom: 5vh;
        margin-left:3vw;
        margin-right:3vw;
    }
    #sec2-badges{
        width:94vw;
        display: grid;
        margin-top:2vh;
        grid-template-columns: repeat(2,44vw);
        grid-gap:4vw;
    }
    .sec2-badge{
        width:44vw;
        height:10vh;
        border-radius: 10px;
    background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:1vh;
    margin-bottom:1vh;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 18.815px;
    font-style: normal;
    font-weight: 600;
    line-height: 41.722px; /* 150% */
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #section-two{
        width:90vw;
        margin-left:5vw;
        margin-bottom:5vw;
        margin-top:3.4vh;
        margin-bottom: 0;
    }
    #sec2-badges{
        width:86vw;
        display: grid;
        grid-template-columns: repeat(4,20vw);
        padding-left:1vw;
        padding-right:1vw;
        grid-gap:2.5vw;
    }
    .sec2-badge{
        width:20vw;
        height:7vh;
        border-radius: 10px;
    background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
    display:flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 23.815px;
    font-style: normal;
    font-weight: 600;
    line-height: 41.722px; /* 150% */
    }
}
@media only screen and (min-width:961px){
    #section-two{
        width:100vw;
        margin-top:-25vh;
        margin-bottom: 15vh;
    }
    #sec2-badges{
        width:78vw;
        padding-left:2vw;
        padding-right:2vw;
        display: grid;
        margin-top:16vh;
        grid-template-columns: repeat(4,18vw);
        gap:1.5vw;
        margin-left:9vw;
        margin-right:9vw;
    }
    .sec2-badge{
        width:18vw;
        height:14vh;
        border-radius: 10px;
    background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
    display:flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 23.815px;
    font-style: normal;
    font-weight: 600;
    line-height: 41.722px; /* 150% */
    }
}
</style>