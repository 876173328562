<template>
 <div id="wrapper">
  <transition name="fade-zoom-slide" mode="out-in">
    <router-view />
  </transition>

 </div>
</template>

<style scoped>

@media only screen and (max-width:480px){
  #wrapper{
    width:100vw;
    top:0;
    left:0;
  }
}
@media only screen and (min-width:481px) and (max-width:960px){
  #wrapper{
    width:100vw;
    top:0;
    left:0;
  }
}
@media only screen and (min-width:961px){
  #wrapper{
    width:100vw;
    top:0;
    left:0;
  }
  .fade-zoom-slide-enter-active,
  .fade-zoom-slide-leave-active {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .fade-zoom-slide-enter,
  .fade-zoom-slide-leave-to {
    opacity: 0;
    transform: scale(0.8) translateX(50%);
  }
  
  /* Zoom in and slide left */
  .fade-zoom-slide-enter {
    opacity: 0;
    transform: scale(1.2) translateX(-50%);
  }
  
  /* Zoom out and slide right */
  .fade-zoom-slide-leave-to {
    opacity: 0;
    transform: scale(0.8) translateX(50%);
  }
}
</style>