<template>
    <div id="mobile6">
        <div id="mobile6-1">
            <div id="mobile6-tab-1">
                <h2>30</h2>
                <h4>%</h4>
                <h3>Companies leveraging blockchain witness a  increase in supply chain transparency.</h3>
            </div>
            <div style="width:94vw;display:flex;flex-direction:row;gap:3vw">
                <div id="mobile6-tab-2">
                    <h2>30</h2>
                    <h4>%</h4>
                    <h3>Increase in transparency</h3>
                </div>
                <div id="mobile6-tab-3">
                    <h2>50</h2>
                    <h4>%</h4>
                    <h3>Reduction in data discrepancies and errors</h3>
                </div>
            </div>
        </div>
        <div id="mobile6-2">
            <div class="mobile6-content">
                <h2>Maximizing Benefits: The Manage Advantage</h2>
                <p>Enjoy unparalleled advantages with The Manage, including cost reduction, risk mitigation, one-stop documentation, enhanced traceability, seamless peer communication, and robust mobileurity measures. Our blockchain solutions are meticulously designed to elevate your supply chain performance, ensuring enduring success for your business.</p>
            </div>
            <div class="mobile6-img">
                <img :src="require('../../assets/landing/images/image-four.png')" alt="Managed">
            </div>
        </div>
        <div id="mobile6-3">
            <div class="mobile6-img">
                <img :src="require('../../assets/landing/images/image-five.png')" alt="Managed">
            </div>
            <div class="mobile6-content">
                <h2>Elevate Your Operations with Unmatched Supply Chain Control</h2>
                <p>The Manage outshines other supply chain solutions with its unwavering commitment to client-centricity, customization, and unmatched scalability by integrating blockchain in supply chain management. Distinguishing itself from conventional platforms, The Manage engages in collaborative partnerships with clients, providing tailored blockchain solutions that address unique challenges head-on. The Manage redefines supply chain management, offering a blend of innovation, reliability, and steadfast dedication to the enduring success of our clients.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
    #mobile6{
        width:94vw;
        overflow-x:hidden;
        margin-top:7vh;
        overflow:hidden;
        padding-left:3vw;
        padding-right:3vw;
    }
    #mobile6-1{
        width:94vw;
    }
    #mobile6-tab-1{
        width:94vw;
        border-radius: 20px;
background: linear-gradient(180deg, #2310B4 0%, #4B4AF4 100%);
height:12vh;
display:flex;
flex-direction: row;
align-items: center;
margin-bottom:2.75vh;
    }
    #mobile6-tab-1 h2{
        color: #FFF;
        text-align: center;
        font-family: Quicksand;
        font-size: 35.431px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left:4vw;
    }
    #mobile6-tab-1 h3{
        color: #FFF;
        text-align: center;
        font-family: sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-wrap: break-word;
        margin-left:0
    }
    #mobile6-tab-1 h4{
        color: #FFF;
        font-family: Quicksand;
        font-size: 14.666px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left:0.75vw;
        margin-top:5.5vh;
    }
    #mobile6-tab-2{
        width:40vw;
        height:12vh;
        border-radius: 17px;
background: linear-gradient(180deg, #2310B4 0%, #4B4AF4 100%);
display:flex;
flex-direction: row;
align-items:center;
    }
    #mobile6-tab-2 h2{
        color: #FFF;
         text-align: center;
        font-family: Quicksand;
        font-size: 35.431px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left:2vw;
    }
    #mobile6-tab-2 h4{
        color: #FFF;
        font-family: Quicksand;
        font-size: 14.666px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top:5.5vh;
        margin-left:0.45vw;
    }
    #mobile6-tab-2 h3{
        color: #FFF;
        font-family: sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left:2vw;
    }
    #mobile6-tab-3{
        width:50vw;
        height:12vh;
        border-radius: 20px;
background: linear-gradient(180deg, #2310B4 0%, #4B4AF4 100%);
display:flex;
flex-direction: row;
align-items:center;
    }
    #mobile6-tab-3 h2{
        color: #FFF;
        text-align: center;
       font-family: Quicksand;
       font-size: 35.431px;
       font-style: normal;
       font-weight: 600;
       line-height: normal;
       margin-left:2vw;
    }
    #mobile6-tab-3 h4{
        color: #FFF;
        font-family: Quicksand;
        font-size: 14.666px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top:5.5vh;
        margin-left:0.45vw;
    }
    #mobile6-tab-3 h3{
        color: #FFF;
        font-family: sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left:2vw;
    }

    #mobile6-2{
        width:94vw;
        margin-top:5.75vh;
    }
    #mobile6-3{
        width:94vw;
        margin-top:5.75vh;
        display: flex;
        flex-direction: column-reverse;
    }
    .mobile6-img{
        width:94vw;
    }
    .mobile6-img img{
        width:94vw;
        height:70%;
    }
    .mobile6-content{
        width:94vw;
    }
    .mobile6-content h2{
        color: #FFF;

        font-family: sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
         margin-bottom:2.5vh;
    }
    .mobile6-content p{
        color: #FFF;

        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        margin-top:0
    }
}
@media only screen and (min-width:481px) and (max-width:960px){
    #mobile6{
        width:90vw;
        overflow-x:hidden;
        margin-top:3vh;
        overflow:hidden;
        padding-left:5vw;
        padding-right:5vw;
    }
    #mobile6-1{
        width:90vw;
        margin-bottom:7vh;
    }
    #mobile6-tab-1{
        width:90vw;
        border-radius: 20px;
background: linear-gradient(180deg, #2310B4 0%, #4B4AF4 100%);
height:11vh;
display:flex;
flex-direction: row;
align-items: center;
margin-bottom:2.75vh;
    }
    #mobile6-tab-1 h2{
        color: #FFF;
        text-align: center;
        font-family: Quicksand;
        font-size: 65.431px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left:4vw;
    }
    #mobile6-tab-1 h3{
        color: #FFF;
        text-align: center;
        font-family: sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-wrap: break-word;
        margin-left:-1vw
    }
    #mobile6-tab-1 h4{
        color: #FFF;
        font-family: Quicksand;
        font-size: 21.666px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left:0.75vw;
        margin-top:5.5vh;
    }
    #mobile6-tab-2{
        width:40vw;
        height:10vh;
        border-radius: 17px;
background: linear-gradient(180deg, #2310B4 0%, #4B4AF4 100%);
display:flex;
flex-direction: row;
align-items:center;
    }
    #mobile6-tab-2 h2{
        color: #FFF;
         text-align: center;
        font-family: Quicksand;
        font-size: 55px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left:2vw;
    }
    #mobile6-tab-2 h4{
        color: #FFF;
        font-family: Quicksand;
        font-size: 19.666px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top:5.5vh;
        margin-left:0.45vw;
    }
    #mobile6-tab-2 h3{
        color: #FFF;
        font-family: sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left:2vw;
    }
    #mobile6-tab-3{
        width:50vw;
        height:10vh;
        border-radius: 20px;
background: linear-gradient(180deg, #2310B4 0%, #4B4AF4 100%);
display:flex;
flex-direction: row;
align-items:center;
    }
    #mobile6-tab-3 h2{
        color: #FFF;
        text-align: center;
       font-family: Quicksand;
       font-size: 55.431px;
       font-style: normal;
       font-weight: 600;
       line-height: normal;
       margin-left:2vw;
    }
    #mobile6-tab-3 h4{
        color: #FFF;
        font-family: Quicksand;
        font-size: 19.666px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top:5.5vh;
        margin-left:0.45vw;
    }
    #mobile6-tab-3 h3{
        color: #FFF;
        font-family: sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left:2vw;
    }

    #mobile6-2{
        width:90vw;
        margin-top:3.75vh;
    }
    #mobile6-3{
        width:90vw;
        margin-top:3.75vh;
        display: flex;
        flex-direction: column-reverse;
    }
    .mobile6-img{
        width:90vw;
    }
    .mobile6-img img{
        width:90vw;
        height:40vh;
    }
    .mobile6-content{
        width:90vw;
    }
    .mobile6-content h2{
        color: #FFF;

        font-family: sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
         margin-bottom:2.5vh;
    }
    .mobile6-content p{
        color: #FFF;

        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height:34px;
        margin-top:0
    }
}
@media only screen and (min-width:961px){
#mobile6{
    display: none;
}

}
  
</style>