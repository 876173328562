<template>
    <div>
     <swiper
     :spaceBetween="10"
     :autoplay="{
       delay: 1500,
       disableOnInteraction: false,
     }"
     :loop="true"
     :slides-per-view="2"
     :modules="modules"
     id="tab2">
 
     <swiper-slide class='tab2-badge'>
         <h2>Customization Excellence</h2>
             <h3>Collaborating closely with our clients, we understand their unique supply chain challenges, tailoring and building solutions that precisely align with their specific needs.</h3>
       </swiper-slide>
       <swiper-slide class='tab2-badge'>
         <h2>Intelligent Insights</h2>
             <h3>Our intelligent solution harnesses data to provide actionable insights, addressing and resolving the exact issues faced by the client for a smarter and more informed supply chain.</h3>
       </swiper-slide>
       <swiper-slide class='tab2-badge'>
         <h2>Effortless User Experience</h2>
             <h3>Designed with diverse stakeholders in mind, our user-friendly dashboards empower clients with easy navigation and enhanced control over their supply chain, ensuring a seamless experience.</h3>
       </swiper-slide>
       <swiper-slide class='tab2-badge'>
         <h2>Scalability at Its Core</h2>
             <h3>Adapting to evolving client needs, we offer highly scalable solutions that grow with our client’s business, ensuring long-term success in an ever-changing business landscape.</h3>
       </swiper-slide>
 
   </swiper>
    </div>
   </template>
   <script>
     // Import Swiper Vue.js components
     import { Swiper, SwiperSlide } from 'swiper/vue';
   
     // Import Swiper styles
     import 'swiper/css';
     // import required modules
     import { Autoplay} from 'swiper/modules';
   
     export default {
       components: {
         Swiper,
         SwiperSlide,
       },
       setup() {
         return {
           modules: [Autoplay],
         };
       },
     };
   </script>
 
 <style scoped>
 @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
 @media only screen and (max-width:480px){
   #tab2{
    display: none;
   }
 }
 @media only screen and (min-width:481px) and (max-width:960px){
    #tab2{
        width:90vw;
        margin-left:5vw;
        margin-right:5vw;
        overflow:hidden;
        display:flex;
        gap:3.5vw;
        margin-top:4.5vh;
        overflow-x:auto;
    }
    #tab2::-webkit-scrollbar{
        display:none;
    }
    .tab2-badge{
        height:18vh;
        border-radius: 10px;
background: linear-gradient(180deg, #220FB3 0%, #4D4CF7 100%);
padding-left:1vw;
padding-right:1vw;
display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
    }
    .tab2-badge h2{
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 17.944px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom:0;
    }
    .tab2-badge h3{
      color: #FFF;

      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.963px;
      margin-top:1vh;
    }
 }
 @media only screen and (min-width:961px){
    #tab2{
        display:none
    }
 }
 </style>
   